import React from 'react';

import Container from 'src/components/Container';

const Project = () => {
  return (
    <Container>
      <iframe
        title="Moku Moku the world"
        src="https://player.vimeo.com/video/617591648?h=4dcb6f1e97"
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
      <p>
        <a href="https://vimeo.com/617591648">MOKU MOKU the world</a> from{' '}
        <a href="https://vimeo.com/macopon123">Mako Ueda</a> on{' '}
        <a href="https://vimeo.com">Vimeo</a>.
      </p>

      <br></br>

      <iframe
        title="first light"
        src="https://player.vimeo.com/video/457640622"
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
      <p>
        <a href="https://vimeo.com/457640622">
          First Light- 夜明けを共に迎えて
        </a>{' '}
        from <a href="https://vimeo.com/macopon123">Mako Ueda</a> on{' '}
        <a href="https://vimeo.com">Vimeo</a>.
      </p>

      <br></br>

      <iframe
        title="one moment"
        src="https://player.vimeo.com/video/142209678"
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
      <p>
        <a href="https://vimeo.com/142209678">
          「One Moment 」- 桜の花が舞う生と死、そこに立ち現れるもののあはれ
        </a>{' '}
        from <a href="https://vimeo.com/macopon123">Mako Ueda</a> on{' '}
        <a href="https://vimeo.com">Vimeo</a>.
      </p>
      <script
        async
        src="https://platform.twitter.com/widgets.js"
        charSet="utf-8"
      ></script>

      <br></br>

      <iframe
        title="innovation tokyo"
        width="640"
        height="360"
        src="https://www.youtube.com/embed/92Z-BTxSHLo"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <p>
        <a href="https://www.youtube.com/embed/92Z-BTxSHLo">
          INNOVATION TOKYO 2018
        </a>{' '}
        from{' '}
        <a href="https://www.youtube.com/embed/92Z-BTxSHLo">RoppongiHills</a> on{' '}
        <a href="https://youtube.com">Youtube</a>.
      </p>
      <p>UX Design by Mako Ueda</p>
      <br></br>

      <img
        src="https://4.bp.blogspot.com/-gxNcq7EN52E/XM4tYrPsayI/AAAAAAADyg0/qgz7SM-zoRcTi-_4esKwQVh1LnRRZqyfACKgBGAs/s640/MVIMG_20190503_190607.jpg"
        alt="The INGRESS Biocard interactive installation』"
        width="640"
        height="360"
      />
      <p>
        <a href="https://fumit.blogspot.com/search?updated-max=2019-05-06T13:51:00%2B09:00&max-results=7&start=7&by-date=falsef">
          INGRESS Interactive Installation Biocard Wall
        </a>
      </p>

      <br></br>

      <img
        src="https://pokemongo-soku.com/wp-content/uploads/2018/03/character_3_1-1.jpg"
        alt="文化庁メディア芸術祭 中国・厦門展 2018『CHARACTER』"
        width="500"
        height="354"
      />
      <p>
        <a href="https://www.bunka.go.jp/koho_hodo_oshirase/hodohappyo/__icsFiles/afieldfile/2018/02/16/a1401466_01.pdf">
          文化庁メディア芸術祭 中国・厦門展 2018『CHARACTER』
        </a>
      </p>

      <br></br>

      <iframe
        title="相棒ポケモンと記念撮影"
        width="640"
        height="360"
        src="https://www.youtube.com/embed/r859ZzUd4dU"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <p>
        <a href="https://www.youtube.com/embed/r859ZzUd4dU">
          相棒ポケモンと記念撮影！
        </a>{' '}
        from{' '}
        <a href="https://www.youtube.com/channel/UC6mtwI0mj4lcpFvSIz1KiRQ">
          Pokémon GO Japan
        </a>{' '}
        on <a href="https://youtube.com">Youtube</a>
      </p>

      <br></br>
    </Container>
  );
};

export default Project;
